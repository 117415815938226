export default {
  'user.login.userName': 'Uername',
  'user.login.password': 'Password',
  'user.login.username.placeholder': 'Please enter your account',
  'user.login.password.placeholder': 'Please enter your password',
  'user.login.message-invalid-credentials':
    'Invalid username or password（admin/ant.design）',
  'user.login.message-invalid-verification-code': 'Invalid verification code',
  'user.login.tab-login-credentials': 'Credentials',
  'user.login.tab-login-mobile': 'Mobile number',
  'user.login.mobile.placeholder': 'Phone number',
  'user.login.mobile.verification-code.placeholder': 'Verification code',
  'user.login.remember-me': 'Remember me',
  'user.login.forgot-password': 'Forgot password',
  'user.login.sign-in-with': 'Sign in with',
  'user.login.signup': 'Sign up',
  'user.login.login': 'Login',
  'user.register.register': 'Register',
  'user.register.email.placeholder': 'Email',
  'user.register.password.placeholder': 'Password（more than 6 characters）',
  'user.register.password.popover-message':
    'More than 6 characters（include numbers and letters）',
  'user.register.confirm-password.placeholder': 'Confirm password',
  'user.register.get-verification-code': 'Get code',
  'user.register.sign-in': 'Already have an account？',
  'user.register-result.msg': 'Account：registered at {email}',
  'user.register-result.activation-email':
    'The activation email has been sent to your email address and is valid for 24 hours. Please log in to the email in time and click on the link in the email to activate the account.',
  'user.register-result.back-home': 'Back to home',
  'user.register-result.view-mailbox': 'View mailbox',
  'user.email.required': 'Please input your email!',
  'user.email.wrong-format': 'The email address is in the wrong format!',
  'user.userName.required': 'Please input your username!',
  'user.password.required': 'Please input your password!',
  'user.password.twice.msg': 'The passwords entered twice do not match!',
  'user.password.strength.msg': 'Please enter a more complex password!',
  'user.password.strength.strong': 'Strength: strong',
  'user.password.strength.medium': 'Strength: medium',
  'user.password.strength.low': 'Strength: low',
  'user.password.strength.short': 'Strength: too short',
  'user.confirm-password.required': 'Please confirm your password!',
  'user.phone-number.required': 'Please input your phone number!',
  'user.phone-number.wrong-format': 'Malformed phone number!',
  'user.verification-code.required': 'Please input the verification code!',

  'user.login.platform': 'Vmed Health Management System',
  'user.captcha.required': 'Please enter the captcha!',
  'user.login.captcha.placeholder': 'Captcha',
  'user.register.username.placeholder': 'Username',
  'user.username.required': 'Please input your username!',
  'user.register.nickName.placeholder': 'Nickname',
  'user.nickName.required': 'Please input your nickname!'
}
