export default {
  'permission.role.setPermission': 'Set Permission',
  'permission.role.newChildRole': 'New Child Role',
  'permission.role.authorityId': 'Authority Id',
  'permission.role.authorityName': 'Authority Name',
  'permission.role.parentAuthority': 'Parent Authority',
  'permission.role.levelName': 'Authority Level',
  'permission.role.addRole': 'Add Role',
  'permission.role.editRole': 'Edit Role',
  'permission.role.addChildRole': 'Add Child Role',
  'permission.role.rootElement': 'Root Element',
  'permission.role.show': 'Show',
  'permission.role.query': 'Query',
  'permission.role.create': 'Create',
  'permission.role.modify': 'Modify',
  'permission.role.remove': 'Remove',
  'permission.role.export': 'Export',
  'permission.role.menuTitle': 'Menu Title',
  'permission.role.setMenu': 'Set Menu',
  'permission.role.setApi': 'Set Api',
  'permission.menu.addRoot': 'Add Root',
  'permission.menu.addChild': 'Add Child',
  'permission.menu.editChild': 'Edit Child',
  'permission.menu.name': 'Menu Name',
  'permission.menu.path': 'Menu Path',
  'permission.menu.hidden': 'Hidden',
  'permission.menu.parentId': 'Parent Id',
  'permission.menu.sort': 'Sort',
  'permission.menu.component': 'Component',
  'permission.menu.title': 'Title',
  'permission.menu.icon': 'Icon',
  'permission.menu.addMenuParameter': 'Add Menu Parameter',
  'permission.menu.addControllBtn': 'Add Controll Btn',
  'permission.menu.parameterType': 'Parameter Type',
  'permission.menu.parameterKey': 'Parameter Key',
  'permission.menu.parameterValue': 'Parameter Value',
  'permission.menu.desc': 'Description',
  'permission.menu.isRedirect': 'Is Redirect',
  'permission.api.path': 'Path',
  'permission.api.apiGroup': 'Api Group',
  'permission.api.desc': 'Description',
  'permission.api.method': 'Method',
  'permission.api.pathMessage': 'Please input path',
  'permission.api.apiGroupMessage': 'Please input api group',
  'permission.api.descMessage': 'Please input description',
  'permission.api.methodMessage': 'Please input method',
  'permission.api.addApi': 'Add Api',
  'permission.api.editApi': 'Edit Api',
  'permission.user.keyword': 'Keyword',
  'permission.user.authorityId': 'Authority Id',
  'permission.user.startTime': 'Start Time',
  'permission.user.endTime': 'End Time',
  'permission.user.username': 'Username',
  'permission.user.nickName': 'Nick Name',
  'permission.user.phone': 'Phone',
  'permission.user.email': 'Email',
  'permission.user.authorityName': 'Authority Name',
  'permission.dictionary.addRole': 'Add Role',
  'permission.dictionary.editRole': 'Edit Role',
  'permission.dictionary.name': 'Name',
  'permission.group.checkGroupList': 'Check Group List',
  'permission.group.addGroup': 'Add Group',
  'permission.group.editGroup': 'Edit Group',
  'permission.group.id': 'Group Id',
  'permission.group.name': 'Group Name',
  'permission.region.name': 'Region Name',
  'permission.region.list': 'Region List',
  'permission.role.deletePrompt': 'Confirm deleting the current role',
  'permission.role.level-query': 'Level Query',
  'permission.region.regionID': 'Region ID',
  'permission.region.nameRequired': 'Please enter a region name',
  'permission.region.addRegion': 'Add Region',
  'permission.region.editRegion': 'Edit Region'
}
