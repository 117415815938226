export default {
  'permission.role.setPermission': '设置权限',
  'permission.role.newChildRole': '新增子角色',
  'permission.role.authorityId': '角色ID',
  'permission.role.authorityName': '角色名称',
  'permission.role.parentAuthority': '父级角色名',
  'permission.role.levelName': '当前等级名称',
  'permission.role.addRole': '新增角色',
  'permission.role.editRole': '编辑角色',
  'permission.role.addChildRole': '新增子角色',
  'permission.role.rootElement': '根元素',
  'permission.role.show': '显示',
  'permission.role.query': '查询',
  'permission.role.create': '新增',
  'permission.role.modify': '修改',
  'permission.role.remove': '删除',
  'permission.role.export': '导出',
  'permission.role.menuTitle': '设置权限',
  'permission.role.setMenu': '角色菜单',
  'permission.role.setApi': '角色api',
  'permission.menu.addRoot': '新增根菜单',
  'permission.menu.addChild': '新增子菜单',
  'permission.menu.editChild': '编辑菜单',
  'permission.menu.name': '菜单名称',
  'permission.menu.path': '菜单地址',
  'permission.menu.hidden': '是否隐藏',
  'permission.menu.parentId': '父节点',
  'permission.menu.sort': '排序',
  'permission.menu.component': '文件路径',
  'permission.menu.title': '展示名称',
  'permission.menu.icon': '图标',
  'permission.menu.addMenuParameter': '新增菜单参数',
  'permission.menu.addControllBtn': '新增可控按钮',
  'permission.menu.parameterType': '参数类型',
  'permission.menu.parameterKey': '参数Key',
  'permission.menu.parameterValue': '参值',
  'permission.menu.desc': '备注',
  'permission.menu.isRedirect': '重定向',
  'permission.api.path': 'API路径',
  'permission.api.apiGroup': 'API分组',
  'permission.api.desc': 'API简介',
  'permission.api.method': '请求',
  'permission.api.pathMessage': '请输入路径！',
  'permission.api.apiGroupMessage': '请输入API分组！',
  'permission.api.descMessage': '请输入API简介！',
  'permission.api.methodMessage': '请输入请求！',
  'permission.api.addApi': '新增API',
  'permission.api.editApi': '编辑API',
  'permission.user.keyword': '关键词',
  'permission.user.authorityId': '角色id',
  'permission.user.startTime': '开始时间',
  'permission.user.endTime': '结束时间',
  'permission.user.username': '用户名',
  'permission.user.nickName': '昵称',
  'permission.user.phone': '手机号',
  'permission.user.email': '邮箱',
  'permission.user.authorityName': '用户角色',
  'permission.dictionary.addRole': '新增字典',
  'permission.dictionary.editRole': '更新字典',
  'permission.dictionary.name': '名称',
  'permission.group.checkGroupList': '查看区域列表',
  'permission.group.addGroup': '新增组织',
  'permission.group.editGroup': '编辑组织',
  'permission.group.id': '组织ID',
  'permission.group.name': '组织名',
  'permission.region.name': '区域名称',
  'permission.region.list': '区域列表',
  'permission.role.deletePrompt': '确认删除当前角色',
  'permission.role.level-query': '等级查询',
  'permission.region.regionID': '区域ID',
  'permission.region.nameRequired': '请输入区域名',
  'permission.region.addRegion': '新增区域',
  'permission.region.editRegion': '编辑区域'

}
