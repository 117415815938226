export default {
    'menu.home': '首页',
    'menu.dashboard': '仪表盘',
    'menu.dashboard.workplace': '工作台',
    'menu.system': '系统管理',
    'menu.system.list': '账户管理',
    'menu.system.bypassAccount': '子账户管理',
    'menu.system.logging': '登录日志',
    'menu.system.businessDiary': '业务日志',
    'menu.consumer': '用户管理',
    'menu.consumer.list': '用户列表',
    'menu.consumer.horizontal': '用户数据横向对比',
    'menu.consumer.vertical': '用户数据纵向对比',
    'menu.order': '订单管理',
    'menu.order.list': '订单列表',
    'menu.order.pay': '充值',
    'menu.order.details': '	订单详情',
    'menu.datum': '健康数据管理',
    'menu.datum.list': '数据列表',
    'menu.datum.temperature': '体温数据',
    'menu.datum.healthData': '健康数据分布',
    'menu.datum.datumHorizontal': '	个人数据横向对比',
    'menu.datum.datumVertical': '个人数据纵向对比',
    'menu.datum.ecgChart': '心电图',
    'menu.permission': '权限管理',
    'menu.permission.role': '角色管理',
    'menu.permission.list': '菜单管理',
    'menu.permission.api': 'API管理',
    'menu.permission.user': '用户管理',
    'menu.permission.dictionary': '	字典管理',
    'menu.permission.operation': '操作历史',
    'menu.permission.group': '组织',
    'menu.account': '个人页',
    'menu.account.center': '个人中心',
    'menu.account.settings': '个人设置',
    'menu.account.settings.basic': '基本信息',
    'menu.account.settings.changePassword': '修改密码',
    'menu.account.logout': '退出登录'
}
