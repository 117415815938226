export default {
  'app.setting.pagestyle': 'Page Style Setting',
  'app.setting.pagestyle.light': 'Light style',
  'app.setting.pagestyle.dark': 'Dark style',
  'app.setting.pagestyle.realdark': 'Real Dark style',
  'app.setting.themecolor': 'Theme Color',
  'app.setting.navigationmode': 'Navigation Mode',
  'app.setting.content-width': 'Content Width',
  'app.setting.fixedheader': 'Fixed Header',
  'app.setting.fixedsidebar': 'Fixed Sidebar',
  'app.setting.sidemenu': 'Side Menu',
  'app.setting.topmenu': 'Top Menu',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.othersettings': 'Other Settings',
  'app.setting.weakmode': 'Weak Mode',
  'app.setting.copy': 'Copy Setting',
  'app.setting.loading': 'Loading Setting',
  'app.setting.copyinfo': 'Copy Setting success src/config/defaultSettings.js',
  'app.setting.production.hint':
    '配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件',
  'app.setting.themecolor.daybreak': 'Daybreak',
  'app.setting.themecolor.dust': 'Dust',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Green',
  'app.setting.themecolor.geekblue': 'Geekblue',
  'app.setting.themecolor.purple': 'Purple'
}
