export default {
  'datum.list.id': 'ID',
  'datum.list.account': '账户',
  'datum.list.resourceType': '数据来源',
  'datum.list.uploadTime': '上传时间',
  'datum.list.systolic': '收缩压',
  'datum.list.diastolic': '舒张压',
  'datum.list.spo2': '血氧饱和度',
  'datum.list.heartRate': '心率',
  'datum.list.fatigue': '疲劳指数',
  'datum.list.pressure': '压力指数',
  'datum.list.respiratoryRate': '呼吸率',
  'datum.list.RespiratoryVariability': '呼吸变异性',
  'datum.list.heartRateVariability': '心率变异性',
  'datum.list.usernameMessage': '请选择用户账户',
  'datum.list.systolicMessage': '请输入收缩压',
  'datum.list.diastolicMessage': '请输入舒张压',
  'datum.list.spo2Message': '请输入血氧饱和度',
  'datum.list.heartRateMessage': '请输入心率',
  'datum.list.fatigueMessage': '请输入疲劳指数',
  'datum.list.pressureMessage': '请输入压力指数',
  'datum.list.respiratoryRateMessage': '请输入呼吸率',
  'datum.list.heartRateVariabilityMessage': '请输入心率变异性',
  'datum.list.keyword': '关键词',
  'datum.list.keywordPlaceholder': '请输入关键词',
  'datum.list.orderKey': '请输入关键词',
  'datum.list.add': '新增数据',
  'datum.list.edit': '编辑数据',
  'datum.list.temperature': '体温',
  'datum.temperature.keyword': '账户',
  'datum.temperature.orderKey': '排序关键词',
  'datum.temperature.isSort': '是否倒叙',
  'datum.temperature.username': '账户',
  'datum.temperature.uploadTime': '测量时间',
  'datum.temperature.temperature': '体温',
  'datum.temperature.systolic': '收缩压',
  'datum.temperature.diastolic': '舒张压',
  'datum.temperature.heart_rate': '心率',
  'datum.temperature.spo2': 'spo2',
  'datum.temperature.fatigue': '疲劳指数',
  'datum.temperature.pressure': '压力指数',
  'datum.temperature.respirator_rate': '呼吸率',
  'datum.healthData.sex': '性别',
  'datum.healthData.age': '年龄',
  'datum.healthData.healthIndex': '健康指标',
  'datum.healthData.bloodPressure': '血压',
  'datum.healthData.spo2': '血氧',
  'datum.healthData.heartRate': '心率',
  'datum.healthData.fatigue': '疲劳指数',
  'datum.healthData.pressure': '压力指数',
  'datum.healthData.temperature': '体温',
  'datum.healthData.heartRateVariability': '心率变异性',
  'datum.datumHorizontal.avgData': '均值',
  'datum.datumHorizontal.personData': '个人',
  'datum.healthGuidance': '健康指导',
  'datum.healthGuidance.text': '根据你的健康水平,坚持每天运动半小时，保持良好的饮食习惯,系统已发送通知给家庭医生',
  'datum.toggleBar': '切换柱状图',
  'datum.toggleLine': '切换折线图',
  'datum.reportCard': '检验报告单',
  'datum.downloadReport': '下载报告',
  'datum.createReport': '生成报告',
  'datum.list.title': '项目名称',
  'datum.list.result': '结果',
  'datum.list.unit': '单位',
  'datum.list.reference': '参考区间',
  'datum.list.samplingTime': '采样时间',
  'datum.list.reportTime': '报告时间',
  'datum.list.createTime': '打印时间',
  'datum.list.cardHint': '本报告仅对该样本负责，结果供医师参考 ',
  'datum.temperature.unit': '℃',
  'datum.list.name': '姓名',
  'datum.list.sex': '性别',
  'datum.list.age': '年龄',
  'datum.temperature.temperatureStatus': '体温状态',
  'datum.temperature.temperatureStatus01': '低温',
  'datum.temperature.temperatureStatus02': '正常体温',
  'datum.temperature.temperatureStatus03': '低烧',
  'datum.temperature.temperatureStatus04': '高烧',
  'datum.temperature.temperatureStatus05': '超高烧',
  'datum.checkECG': '查看心电图',
  'datum.ecgChart.ecg': '心电图',
  'datum.ecgChart.ppg': '静脉图',
  'datum.switchChart': '切换图表',
  'datum.normal-blood-pressure': '正常血压',
  'datum.low-blood-pressure': '低血压',
  'datum.high-blood-pressure': '高血压',
  'datum.normal-spo2': '正常血氧饱和度',
  'datum.more-low-spo2': '血氧过低',
  'datum.low-spo2': '血氧偏低',
  'datum.too-fast-heartRate': '心率过快',
  'datum.normal-heartRate': '心率过快',
  'datum.low-heartRate': '低心率',
  'datum.high-temperature': '体温过高',
  'datum.normal-temperature': '体温正常',
  'datum.low-temperature': '体温过低',
  'datum.list.ecgHint': '如有需要可单独下载查看ECG'
}
