// import antd from 'ant-design-vue/es/locale-provider/es_ES'
import momentES from 'moment/locale/es'
import global from './zh-CN/global'

import menu from './zh-CN/menu'
import setting from './zh-CN/setting'
import user from './zh-CN/user'
import dashboard from './zh-CN/dashboard'
import result from './zh-CN/result'
import account from './zh-CN/account'
import permission from './zh-CN/permission'
import datum from './zh-CN/datum'
import consumer from './zh-CN/consumer'
import system from './zh-CN/system'
import order from './zh-CN/order'

const components = {
  // antLocale: antd,
  momentName: 'es-ES',
  momentLocale: momentES
}

export default {
  message: '-',
  'layouts.usermenu.dialog.title': '信息',
  'layouts.usermenu.dialog.content': '您确定要注销吗？',
  'layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  ...components,
  ...global,
  ...menu,
  ...setting,
  ...user,
  ...dashboard,
  ...result,
  ...account,
  ...permission,
  ...datum,
  ...consumer,
  ...system,
  ...order
}
