export default {
  'consumer.list.userName': '用户名',
  'consumer.list.account': '账户',
  'consumer.list.name': '昵称',
  'consumer.list.phone': '手机号',
  'consumer.list.email': '邮箱',
  'consumer.list.createTime': '注册时间',
  'consumer.list.status': '当前状态',
  'consumer.list.birthDay': '出生日期',
  'consumer.list.height': '身高',
  'consumer.list.weight': '体重',
  'consumer.list.baseInfo': '基本状况',
  'consumer.list.authority': '角色',
  'consumer.list.sex': '性别',
  'consumer.list.isVip': '会员',
  'consumer.list.freeze': '冻结',
  'consumer.list.normal': '正常',
  'consumer.list.man': '男',
  'consumer.list.woman': '女',
  'consumer.list.medicationHistory': '用药史',
  'consumer.list.medicationHistoryTime': '用药时间',
  'consumer.list.password': '密码',
  'consumer.list.repeatPassword': '二次密码',
  'consumer.list.active': '是否启用',
  'consumer.list.addUser': '新增用户',
  'consumer.list.editUser': '编辑用户',
  'consumer.list.nameTip': '请输入昵称',
  'consumer.list.heightTip': '请输入身高',
  'consumer.list.heightUnitTip': '请输入身高单位',
  'consumer.list.weightTip': '请输入体重',
  'consumer.list.weightUnitTip': '请输入体重单位',
  'consumer.list.sexTip': '请选择性别',
  'consumer.list.birthDayTip': '请选择生日',
  'consumer.list.medicationHistoryTip': '请输入用药史',
  'consumer.list.userNameTip': '请输入用户名',
  'consumer.list.emailTip': '请输入邮箱',
  'consumer.list.phoneTip': '请输入手机号',
  'consumer.list.passwordTip': '请输入密码',
  'consumer.list.repeatPasswordTip': '请输入二次密码',
  'consumer.list.authorityPlaceholder': '选择角色',
  'consumer.list.region': '区域',
  'consumer.list.personalStatusTip': '选择用药状态',
  'consumer.list.none': '无',
  'consumer.list.hypertension': '高血压药',
  'consumer.list.hypotension': '低血压药',
  'consumer.list.authorityName': '角色名称',
  'datum.healthData.isVip': '是否会员',
  'consumer.list.userNamePlaceholder': '请输入账户',
  'consumer.list.authorityNamePlaceholder': '请输入角色名称',
  'consumer.list.checkData01': '数据横向对比',
  'consumer.list.checkData02': '数据纵向对比',
  'consumer.list.isStatus': '是否冻结',
  'consumer.list.status01': '冻结',
  'consumer.list.status02': '正常',
  'consumer.list.authorityLevel': '角色等级'
}
