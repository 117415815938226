export default {
  'consumer.list.userName': 'User name',
  'consumer.list.account': 'Account',
  'consumer.list.name': 'Name',
  'consumer.list.phone': 'mobile phone',
  'consumer.list.email': 'email',
  'consumer.list.createTime': 'register time',
  'consumer.list.status': 'status',
  'consumer.list.birthDay': 'birthday',
  'consumer.list.height': 'height',
  'consumer.list.weight': 'weight',
  'consumer.list.baseInfo': 'base info',
  'consumer.list.authority': 'Role',
  'consumer.list.sex': 'gender',
  'consumer.list.isVip': 'VIP',
  'consumer.list.freeze': 'frozen',
  'consumer.list.normal': 'normal',
  'consumer.list.man': 'male',
  'consumer.list.woman': 'female',
  'consumer.list.medicationHistory': 'Medication history',
  'consumer.list.medicationHistoryTime': 'Medication Time',
  'consumer.list.password': 'password',
  'consumer.list.repeatPassword': 'Confirm password',
  'consumer.list.active': 'active',
  'consumer.list.addUser': 'add user',
  'consumer.list.editUser': 'Edit user',
  'consumer.list.nameTip': 'Please enter nick name',
  'consumer.list.heightTip': 'Please enter height',
  'consumer.list.heightUnitTip': 'Please enter height unit',
  'consumer.list.weightTip': 'Please enter weight',
  'consumer.list.weightUnitTip': 'Please enter weight unit',
  'consumer.list.sexTip': 'Please select gender',
  'consumer.list.birthDayTip': 'Please select a birthday',
  'consumer.list.medicationHistoryTip': 'Please enter medication history',
  'consumer.list.userNameTip': 'Please enter account',
  'consumer.list.emailTip': 'Please enter email',
  'consumer.list.phoneTip': 'Please enter phone number',
  'consumer.list.passwordTip': 'Please enter password',
  'consumer.list.repeatPasswordTip': 'Please enter confirm password',
  'consumer.list.authorityPlaceholder': 'Choose role',
  'consumer.list.region': 'Region',
  'consumer.list.personalStatusTip': 'Selective medication status',
  'consumer.list.none': 'none',
  'consumer.list.hypertension': 'Hypertension',
  'consumer.list.hypotension': 'Hypotension',
  'consumer.list.authorityName': 'Role name',
  'datum.healthData.isVip': 'VIP',
  'consumer.list.userNamePlaceholder': 'Please enter account',
  'consumer.list.authorityNamePlaceholder': 'Please enter role name',
  'consumer.list.checkData01': 'Data Horizontal Comparison',
  'consumer.list.checkData02': 'Data Vertical Comparison',
  'consumer.list.isStatus': 'Is Blocked',
  'consumer.list.status01': 'Blocked',
  'consumer.list.status02': 'Normal',
  'consumer.list.authorityLevel': 'Role Level'
}
