export default {
  'dashboard.analysis.test': '工专路 {no} 号店',
  'dashboard.analysis.introduce': 'Introduction',
  'dashboard.analysis.total-sales': 'Total sales',
  'dashboard.analysis.day-sales': 'Daily sales',
  'dashboard.analysis.visits': 'Visits',
  'dashboard.analysis.visits-trend': 'Visits trend',
  'dashboard.analysis.visits-ranking': 'Visits ranking',
  'dashboard.analysis.day-visits': 'Daily visits',
  'dashboard.analysis.week': 'Week performance',
  'dashboard.analysis.day': 'Day performance',
  'dashboard.analysis.payments': 'Payments',
  'dashboard.analysis.conversion-rate': 'Conversion rate',
  'dashboard.analysis.operational-effect': 'Operational effect',
  'dashboard.analysis.sales-trend': 'Stores sales',
  'dashboard.analysis.sales-ranking': 'Sales ranking',
  'dashboard.analysis.all-year': 'All year',
  'dashboard.analysis.all-month': 'All month',
  'dashboard.analysis.all-week': 'All week',
  'dashboard.analysis.all-day': 'All day',
  'dashboard.analysis.search-users': 'Search Users',
  'dashboard.analysis.per-capita-search': 'Per Capita Search',
  'dashboard.analysis.online-top-search': 'Online Top Search',
  'dashboard.analysis.the-proportion-of-sales': 'The Proportion Of Sales',
  'dashboard.analysis.dropdown-option-one': 'Option A',
  'dashboard.analysis.dropdown-option-two': 'Option B',
  'dashboard.analysis.channel.all': 'ALL',
  'dashboard.analysis.channel.online': 'Online',
  'dashboard.analysis.channel.stores': 'Stores',
  'dashboard.analysis.sales': 'Sales',
  'dashboard.analysis.traffic': 'Traffic',
  'dashboard.analysis.table.rank': 'Rank',
  'dashboard.analysis.table.search-keyword': 'Keyword',
  'dashboard.analysis.table.users': 'Users',
  'dashboard.analysis.table.weekly-range': 'Weekly Range',

  'dashboard.Workplace.visitor-volume': 'Visitor Volume',
  'dashboard.Workplace.visitor-day': 'Visitor Day',
  'dashboard.Workplace.visits-trend': 'Visits Trend',
  'dashboard.Workplace.user-volume': 'Users',
  'dashboard.Workplace.user-yesterday': 'New users added yesterday',
  'dashboard.Workplace.user-week': 'latest 7 days',
  'dashboard.Workplace.user-trend': 'User Trend',
  'dashboard.Workplace.metrical-data': 'Measurements',
  'dashboard.Workplace.metrical-yesterday': 'Number of measurements taken yesterday',
  'dashboard.Workplace.metrical-week': 'latest 7 days',
  'dashboard.Workplace.metrical-trend': 'Metrical Trend'
}
