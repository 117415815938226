import Vue from 'vue';

import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      meta:{
        title: ''
      },
      component: resolve => require(['@/views/index/index'], resolve) // 用户注册协议
    },

  ]
});
