export default {
  'datum.list.id': 'ID',
  'datum.list.account': 'Account',
  'datum.list.resourceType': 'Resource',
  'datum.list.uploadTime': 'Upload Time',
  'datum.list.systolic': 'Systolic',
  'datum.list.diastolic': 'Diastolic',
  'datum.list.spo2': 'SpO2',
  'datum.list.heartRate': 'Heart Rate',
  'datum.list.fatigue': 'Fatigue',
  'datum.list.pressure': 'Pressure',
  'datum.list.respiratoryRate': 'Respiratory Rate',
  'datum.list.RespiratoryVariability': 'Respiratory Variability',
  'datum.list.heartRateVariability': 'Heart rate variability',
  'datum.list.usernameMessage': 'Username',
  'datum.list.systolicMessage': 'Systolic',
  'datum.list.diastolicMessage': 'Diastolic',
  'datum.list.spo2Message': 'SpO2',
  'datum.list.heartRateMessage': 'Heart Rate',
  'datum.list.fatigueMessage': 'Fatigue',
  'datum.list.pressureMessage': 'Pressure',
  'datum.list.respiratoryRateMessage': 'Respiratory Rate',
  'datum.list.heartRateVariabilityMessage': 'Heart Rate Variability',
  'datum.list.keyword': 'Keyword',
  'datum.list.keywordPlaceholder': 'Please enter the keyword',
  'datum.list.orderKey': 'Order Key',
  'datum.list.add': 'Add',
  'datum.list.edit': 'Edit',
  'datum.list.temperature': 'Temperature',
  'datum.temperature.keyword': 'Account',
  'datum.temperature.orderKey': 'Order Key',
  'datum.temperature.isSort': 'Sort',
  'datum.temperature.username': 'Username',
  'datum.temperature.uploadTime': 'Upload Time',
  'datum.temperature.temperature': 'Temperature',
  'datum.temperature.systolic': 'Systolic',
  'datum.temperature.diastolic': 'Diastolic',
  'datum.temperature.heart_rate': 'Heart Rate',
  'datum.temperature.spo2': 'spo2',
  'datum.temperature.fatigue': 'Fatigue',
  'datum.temperature.pressure': 'Pressure',
  'datum.temperature.respirator_rate': 'Respiratory Rate',
  'datum.healthData.sex': 'Gender',
  'datum.healthData.age': 'Age',
  'datum.healthData.healthIndex': 'Health Index',
  'datum.healthData.bloodPressure': 'Blood Pressure',
  'datum.healthData.spo2': 'Spo2',
  'datum.healthData.heartRate': 'Heart Rate',
  'datum.healthData.fatigue': 'Fatigue',
  'datum.healthData.pressure': 'Pressure',
  'datum.healthData.temperature': 'Temperature',
  'datum.healthData.heartRateVariability': 'Heart Rate Variability',
  'datum.datumHorizontal.avgData': 'mean value',
  'datum.datumHorizontal.personData': 'personal',
  'datum.healthGuidance': 'Health Guidance',
  'datum.healthGuidance.text':
    'According to your health level, keep exercising for half an hour every day and keep a good diet, the system has sent a notification to the family doctor',
  'datum.toggleBar': 'Bar Chart',
  'datum.toggleLine': 'Line Chart',
  'datum.reportCard': 'Report Card',
  'datum.downloadReport': 'Download Report',
  'datum.createReport': 'Create Report',
  'datum.list.title': 'Item Name',
  'datum.list.result': 'Result',
  'datum.list.unit': 'Unit',
  'datum.list.reference': 'Reference',
  'datum.list.samplingTime': 'Testing Time',
  'datum.list.reportTime': 'Report Time',
  'datum.list.createTime': 'Print Time',
  'datum.list.cardHint':
    'This report is only responsible for this sample, and the results are for the reference of physicians',
  'datum.temperature.unit': '℉',
  'datum.list.name': 'Name',
  'datum.list.sex': 'Sex',
  'datum.list.age': 'Age',
  'datum.temperature.temperatureStatus': 'Temprature Status',
  'datum.temperature.temperatureStatus01': 'Low Temprature',
  'datum.temperature.temperatureStatus02': 'Normal',
  'datum.temperature.temperatureStatus03': 'Low-grade Fever',
  'datum.temperature.temperatureStatus04': 'High Fever',
  'datum.temperature.temperatureStatus05': 'Super High Fever',
  'datum.checkECG': 'Check ECG',
  'datum.ecgChart.ecg': 'ECG',
  'datum.ecgChart.ppg': 'PPG',
  'datum.switchChart': 'Switch Chart',
  'datum.normal-blood-pressure': 'Normal Blood Pressure',
  'datum.low-blood-pressure': 'Low Blood Pressure',
  'datum.high-blood-pressure': 'High Blood Pressure',
  'datum.normal-spo2': 'Normal Spo2',
  'datum.more-low-spo2': 'More Low Spo2',
  'datum.low-spo2': 'Low Spo2',
  'datum.too-fast-heartRate': 'Too Fast HeartRate',
  'datum.normal-heartRate': 'Normal HeartRate',
  'datum.low-heartRate': 'Low HeartRate',
  'datum.high-temperature': 'High Temperature',
  'datum.normal-temperature': 'Normal Temperature',
  'datum.low-temperature': 'Low Temperature',
  'datum.list.ecgHint': 'If necessary, you can download and view the ECG separately'
}
