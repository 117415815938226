export default {
  'order.list.id': 'ID',
  'order.list.account': '账户',
  'order.list.order-no': '订单号',
  'order.list.type': '类型',
  'order.list.create-time': '创建时间',
  'order.list.pay-status': '支付状态',
  'order.list.deal-price': '应付金额',
  'order.list.pay-time': '支付时间',
  'order.list.order-detail': '订单详情',
  'order.list.order-status01': '新购',
  'order.list.order-status02': '续费',
  'order.list.order-status03': '升级',
  'order.list.order-status04': '降级',
  'order.list.order-status05': '退款',
  'order.list.pay-status01': '作废',
  'order.list.pay-status02': '未支付',
  'order.list.pay-status03': '已支付'

}
