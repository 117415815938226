export default {
    'system.list.roleName': '角色名',
    'system.list.createTime': '创建时间',
    'system.list.addRole': '添加角色',
    'system.list.roleDesc': '角色描述',
    'system.list.creator': '创建人',
    'system.list.member': '成员',
    'system.logging.logType': '日志类型',
    'system.logging.userName': '账户',
    'system.logging.name': '用户昵称',
    'system.logging.ip': 'ip',
    'system.logging.agent': '代理',
    'system.logging.message': '具体信息',
    'system.logging.all': '全部',
    'system.logging.login': '登录',
    'system.logging.visitLogin': '游客登录',
    'system.logging.logout': '退出',
    'system.logging.uploadTime': '时间',
    'system.businessDiary.all': '全部',
    'system.businessDiary.type01': '添加用户',
    'system.businessDiary.type02': '设置用户权限',
    'system.businessDiary.type03': '创建角色',
    'system.businessDiary.type04': '删除角色',
    'system.businessDiary.type05': '拷贝角色',
    'system.businessDiary.type06': '编辑角色',
    'system.businessDiary.type07': '添加区域',
    'system.businessDiary.type08': '编辑区域',
    'system.businessDiary.type09': '删除区域',
    'system.businessDiary.type10': '添加组织',
    'system.businessDiary.type11': '编辑组织',
    'system.businessDiary.type12': '删除组织',
    'system.businessDiary.type13': '手动添加健康数据',
    'system.businessDiary.type14': '编辑健康数据',
    'system.businessDiary.type15': '手动添加体温数据',
    'system.businessDiary.type16': '编辑体温数据',
    'system.businessDiary.type17': '编辑系统配置',
    'system.businessDiary.type18': '更新用户信息'
}
