export default {
  'order.list.id': 'ID',
  'order.list.account': 'Account',
  'order.list.order-no': 'Order No.',
  'order.list.type': 'Type',
  'order.list.create-time': 'Create Time',
  'order.list.pay-status': 'Status',
  'order.list.deal-price': 'Price',
  'order.list.pay-time': 'Pay Time',
  'order.list.order-detail': 'Order Detail',
  'order.list.order-status01': 'New',
  'order.list.order-status02': 'Renew',
  'order.list.order-status03': 'Upgrade',
  'order.list.order-status04': 'Downgrade',
  'order.list.order-status05': 'Refund',
  'order.list.pay-status01': 'Cancel',
  'order.list.pay-status02': 'Unpaid',
  'order.list.pay-status03': 'Paid'
}
