export default {
  submit: '提交',
  save: '保存',
  'submit.ok': '提交成功',
  'save.ok': '保存成功',
  'table.add': '新建',
  'table.copy': '拷贝',
  'table.edit': '编辑',
  'table.delete': '删除',
  'table.start_time': '开始时间',
  'table.end_time': '结束时间',
  'table.date': '日期',
  'table.inquire': '查询',
  'table.reset': '重置',
  'table.unfold': '展开',
  'table.fewer': '收起',
  'table.operation': '操作',
  'table.addSuccess': '新增成功',
  'table.editSuccess': '修改成功',
  'table.deleteSuccess': '删除成功',
  'table.deletePrompt': '您确定要删除？',
  'table.ok-text': '确认',
  'table.cancel-text': '取消',
  'table.no-data': '暂无数据',
  'table.yes': '是',
  'table.no': '否',
  'table.batchRemove': '批量删除',
  'table.selected': '已选择',
  'table.pay': '充值',
  'table.viewData': '查看数据',
  'table.detail': '详情',
  'table.show': '显示',
  'table.hidden': '隐藏',
  'table.all': '全部',
  'table.man': '男',
  'table.woman': '女',
  'global.welcome': '欢迎',
  'global.welcomeBack': '欢迎回来',
  'global.error': '错误',
  'global.errorMsg': '请求出现错误，请稍后再试',
  'global.graphPresentation': '图形展示',
  'table.keyword': '关键词',
  'global.keyword': '关键词',
  'global.start_time_placeholder': '请输入开始时间',
  'global.end_time_placeholder': '请输入结束时间',
  'global.select_placeholder': '请选择',
  'global.log_hint': '您确定要删除当前日志？',
  'global.hint': '提示',
  'global.date': '日期',
  'global.back': '返回',
  'table.change-password': '修改密码',
  'global.good-morning': '上午好',
  'global.good-noon': '中午好',
  'global.good-afternoon': '下午好',
  'global.good-evening': '晚上好',
  'show': '显示',
  'add': '新增',
  'delete': '删除',
  'edit': '编辑',
  'query': '查询',
  'get': '详情',
  'enable': '启用',
  'disable': '禁用',
  'import': '导入',
  'export': '导出',
  'set': '设置',
  'copy': '复制',
  'role-level1': '一级普通用户',
  'role-level2': '二级区域管理员',
  'role-level3': '三级组织管理员',
  'role-level4': '四级管理员',
  'global.download': '下载',
  'table.total01': '共',
  'table.total012': '条',
  'global.closeThat': '关闭当前标签',
  'global.closeRight': '关闭右侧',
  'global.closeLeft': '关闭左侧',
  'global.closeAll': '关闭全部',
  'global.resetZoom': '重置放大',
  'table.delete-selected': '删除选中条目',
  'table.deleteLogHint': '至少选中一条'
}
